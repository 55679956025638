import { landing, pricing, workspaces } from "@/api";
import Banner from "@/components/icons/banner";
import Link from "@/components/ui/link";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";

type TopbarProps = {};

export default function Topbar(props: TopbarProps) {
  const currentWorkspace = useCurrentWorkspace();

  return (
    <div className="w-full px-4 py-4 bg-background flex animate-fade-down animate-delay-500">
      <div className="flex-grow flex items-center">
        <Banner withLogo href={landing.index.path()} />
      </div>
      <div className="flex items-center h-full">
        <Link
          href={
            currentWorkspace
              ? workspaces.show.path(currentWorkspace)
              : pricing.show.path()
          }
          variant="default"
          className="rounded-full"
          prefetch
        >
          {currentWorkspace ? "Go to app" : "Pricing + Sign up"}
        </Link>
      </div>
    </div>
  );
}
