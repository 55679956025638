import { landing } from "@/api";
import { buttonVariants } from "@/components/ui/button";
import Link from "@/components/ui/link";
import useEnvironment from "@/lib/hooks/use-environment";
import { cn, isSSR } from "@/lib/utils";
import { t } from "i18next";
import CookieConsentPrimitive from "react-cookie-consent";
import { Trans } from "react-i18next";
import clarityLib from "react-microsoft-clarity";

type CookiesConsentProps = {};

const acceptCookies = () => {
  if (clarityLib.clarity.hasStarted() && !isSSR()) clarityLib.clarity.consent();
};

export const COOKIE_CONSENT_NAME = "cookies_consent_2";

export default function CookiesConsent(props: CookiesConsentProps) {
  const env = useEnvironment();

  if (env === "test" || env === "development" || isSSR()) return null;

  return (
    <CookieConsentPrimitive
      disableButtonStyles
      enableDeclineButton
      disableStyles
      expires={150}
      onAccept={acceptCookies}
      buttonText={t("cookies.accept")}
      cookieName={COOKIE_CONSENT_NAME}
      buttonClasses={cn(buttonVariants({ variant: "default" }))}
      buttonWrapperClasses="flex gap-4"
      declineButtonText={t("cookies.decline")}
      declineButtonClasses={cn(buttonVariants({ variant: "ghost" }))}
      containerClasses="bg-stone-800 text-white flex flex-col md:flex-row items-center justify-center gap-4 p-6 fixed bottom-0 left-0 w-full z-50"
      contentClasses="!text-sm text-center md:text-left block"
    >
      <Trans
        i18nKey={"cookies.disclaimer"}
        components={{
          a: (
            <Link
              href={landing.cookiesPolicy.path()}
              className="p-0 h-fit hover:underline italic text-white"
            />
          ),
        }}
      />
    </CookieConsentPrimitive>
  );
}
