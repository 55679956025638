import Logo from "@/components/icons/logo";
import { PropsWithChildren, ReactNode } from "react";
import CookiesConsent from "./components/cookies-consent";
import { CallToAction } from "./components/landing/call-to-action";
import Footer from "./components/landing/footer";
import Topbar from "./components/landing/topbar";

interface SsrLayoutProps extends PropsWithChildren {}

const FloatingCta = () => (
  <CallToAction className="sm:flex fixed bottom-8 right-8 z-40 shadow-sm !text-md sm:py-4 sm:px-4 animate-fade-up animate-delay-1000 p-4">
    <Logo className="w-6" />
    Try it for free
  </CallToAction>
);

const SsrLayout = ({ children }: SsrLayoutProps) => {
  return (
    <div className="w-full min-h-screen bg-background max-w-[1500px] m-auto flex flex-col">
      <CookiesConsent />
      <Topbar />
      <FloatingCta />
      <div className="flex-grow flex flex-col w-full px-8 md:px-32 py-12 md:pt-32">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default (page: ReactNode) => <SsrLayout>{page}</SsrLayout>;
