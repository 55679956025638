// Entry point for the build script in your package.json
import "@/i18n";
import landingLayout from "@/layouts/landing-layout";
import "@/styles/application.css";
import { InertiaProgress } from "@inertiajs/progress";
import { createInertiaApp } from "@inertiajs/react";
import { Observer } from "@uprootsai/tailwindcss-intersect";
import axios from "axios";
import { createRoot } from "react-dom/client";
import DashboardLayout from "../layouts/dashboard-layout";

type Page = {
  layout?: typeof DashboardLayout;
};

const pages = import.meta.glob("../pages/**/*.tsx") as Record<
  string,
  () => Promise<{ default: Page }>
>;

InertiaProgress.init();

document.addEventListener("DOMContentLoaded", () => {
  const csrfToken = (
    document.querySelector("meta[name=csrf-token]") as HTMLMetaElement
  )?.content;

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  createInertiaApp({
    resolve: async (name) => {
      const asyncPage = pages[`../pages/${name}.tsx`];

      if (!asyncPage)
        throw new Error(
          `Unknown page ${name}. Is it located under pages with a .tsx extension?`,
        );

      const page = (await asyncPage()).default;

      page.layout =
        page.layout ||
        (name.includes("landing") && landingLayout) ||
        DashboardLayout;

      return page;
    },
    setup({ el, App, props }) {
      createRoot(el).render(<App {...props} />);

      // Intersection observer for tailwindcss-intersect
      setTimeout(() => {
        Observer.start();
      }, 50); // Give some time so that the react app can initialize
    },
  });
});
