export const linkTrackersEmptyState =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1725735521/link-trackers-empty-state_qs9gin.png";

export const notifiersEmptyState =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1725735154/notifiers-empty-state_rry9op.png";

export const schedulesEmptyState =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1725734850/schedules-empty-state_tytoey.png";

export const monitorEmptyState =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1725735225/monitor-empty-state_yi7tkp.png";

export const whatsapp =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1725735288/whatsapp_rsr09x.png";

export const x =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1729615458/x_ngavca.png";

export const linkedIn =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1729615458/linked_in_j9djv7.png";

export const facebook =
  "https://res.cloudinary.com/dbr3cq0ae/image/upload/v1729615458/facebook_fvtumu.png";

export const preloadImages = [
  linkTrackersEmptyState,
  notifiersEmptyState,
  monitorEmptyState,
  schedulesEmptyState,
  x,
  linkedIn,
  facebook,
];
