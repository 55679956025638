import landing from "@/api/LandingApi";
import pricing from "@/api/PricingApi";
import Banner from "@/components/icons/banner";
import { facebook, linkedIn, x } from "@/components/images";
import Link from "@/components/ui/link";
import usePageProp from "@/lib/hooks/use-page-prop";

export default function Footer() {
  const brand = usePageProp<{
    company_address: string;
    support_email: string;
    customers_email: string;
    social: {
      facebook: any;
      x: string;
      linked_in: string;
    };
  }>("brand");

  return (
    <div className="w-full mt-48 flex flex-col sm:flex-row gap-16 sm:gap-8 items-start border-t py-12 pb-40 justify-around px-8">
      <div className="grid grid-cols-1 gap-2 text-muted-foreground max-w-xs">
        <Banner
          withLogo
          className="mb-2 !p-0 justify-start !h-auto"
          href={landing.index.path()}
        />
        <div className="text-sm">Copyright © {new Date().getFullYear()}</div>
        <Link
          href={`mailto:${brand.support_email}`}
          target="_blank"
          className="!p-0 justify-start !h-auto"
        >
          {brand.support_email}
        </Link>
        <Link
          href={`mailto:${brand.customers_email}`}
          target="_blank"
          className="!p-0 justify-start !h-auto"
        >
          {brand.customers_email}
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-1">
        <h3 className="mb-4">Social</h3>
        <div className="flex gap-2">
          <Link
            href={`https://x.com/${brand.social.x}`}
            className="justify-start !p-0 !h-auto hover:opacity-50"
            target="_blank"
          >
            <img src={x} className="w-8" alt="X icon" />
          </Link>
          <Link
            href={`https://facebook.com/${brand.social.facebook}`}
            className="justify-start !p-0 !h-auto hover:opacity-50"
            target="_blank"
          >
            <img src={facebook} className="w-8" alt="Facebook icon" />
          </Link>
          <Link
            href={`https://www.linkedin.com/company/${brand.social.linked_in}`}
            className="justify-start !p-0 !h-auto hover:opacity-50"
            target="_blank"
          >
            <img src={linkedIn} className="w-8" alt="linkedIn icon" />
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-1">
        <h3 className="mb-4">Legal</h3>
        <Link
          href={landing.tos.path()}
          className="justify-start !p-0 !h-auto"
          prefetch
        >
          Terms of Service
        </Link>
        <Link
          href={landing.privacy.path()}
          className="justify-start !p-0 !h-auto"
          prefetch
        >
          Privacy Policy
        </Link>
        <Link
          href={landing.cookiesPolicy.path()}
          className="justify-start !p-0 !h-auto"
          prefetch
        >
          Cookies Consent Policy
        </Link>
      </div>

      <div className="grid grid-cols-1">
        <h3 className="mb-4">Product</h3>
        <Link
          href={pricing.show.path()}
          className="justify-start !p-0 !h-auto"
          prefetch
        >
          Pricing
        </Link>
        <Link
          href={landing.realEstate.path()}
          className="justify-start !p-0 !h-auto"
          prefetch
        >
          Real Estate
        </Link>
      </div>
    </div>
  );
}
